.landing-page {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);
  }
  .landing-page h1{
    color: var(--clr-white);
    font-family: var(--ff-primary);
  }
  .content{
    max-width: 1200px;
    margin: 0 auto;
  }
  .landing-header {
    background-color: var(--clr-primary-1);
  }
  .landing-header .content{
    position: relative;
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
  }
  .landing-header .hand{
    position: absolute;
    top: 70%;
    left: 2%;
  }
  .landing-header .hand:hover{
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  .head.move-area {
    position: relative;
    display: inline-block;
  }
  #anchor {
    width: 100%;
    max-width: 400px;
  }
  .head{
    position: relative;
    z-index: 2;
  }
  .eye {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
  }
  .eye-left {
    top: 38%;
    left: 23%;
  }
  .eye-right {
    top: 38.5%;
    right: 48%;
  }

  .online-solution {
    position: relative;
    padding: 50px;
    text-align: center;
    overflow: hidden;
  }
  .online-solution .content {
    position: relative;
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
    z-index: 1;
    color: var(--clr-primary-3);
  }
  .design-point .content {
    background-color: var(--clr-background);
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 4rem;
    font-weight: 900;
    position: relative;
  }
  .design-point .content h2{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 4rem;
    font-weight: 900;
    position: relative;
  }
  .design-point .content .hand-two{
    position: absolute;
    top: 5%;
    right: 2%;

  }
  .design-point .hand-two:hover{
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  .design-point span{
    color: var(--clr-primary-1);
  }

  .industry-leaders {
    background-color: var(--clr-white);
    padding: 50px;
    text-align: center;
  }
  
  .industry-leaders .logos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .industry-leaders .logos img {
    width: 80px; /* Fixed width for all images */
    height: 80px; /* Fixed height for all images */
    object-fit: contain; /* Maintain aspect ratio */
    margin: 10px;
    border-radius: 10px; /* Optional: for rounded corners */
    background-color: #f9f9f9; /* Optional: background color for better visibility */
  }

/* COMPARISON SECTION */
.comparison-section {
  background-color: #f9f9f9;
  padding: 50px 50px 0;
  text-align: center;
  position: relative;
}

.comparison-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Aligns the content to the bottom */
  position: relative;
  height: 100%;
}

.company-x, .company-y {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 200px;
}

.company-x .icon, .company-y .icon{
  position: absolute;
  bottom: 0; 
  width: 250px; 
}

.company-label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px; 
  position: absolute;
  bottom: 260px;
  width: 100%;
  text-align: center;
}

.comparison-content {
  flex: 1;
  margin: 0 40px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px 10px 0 0;
  z-index: 1;
}

.comparison-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.comparison-table {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  background-color: #ffc107;
  padding: 10px;
  font-size: 1.2rem;
}

tbody td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

th, td {
  border-right: 1px solid #ddd;
}

th:last-child, td:last-child {
  border-right: none;
}

tbody td:first-child {
  text-align: left;
}
.green{
  background-color: rgba(200, 255, 200, 0.63);
}


/* END */

.learn-more {
  background-color: var(--clr-primary-3);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more:hover {
  background-color: var(--clr-primary-5);
}

  
  .learn-more {
    background-color: #6c63ff;
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
      justify-content: center;
      align-items: center;
      text-align: center;
  }
  
  .learn-more:hover {
    background-color: #4b48b3;
  }
  
  .why-work-with-us {
    background-color: var(--clr-primary-1);
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
  }
  .why-work-with-us h2{
    color: var(--clr-white);
    font-size: 2rem;
    font-weight: 900;
  }
  
  .why-work-with-us .content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .why-work-with-us img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .client-testimonials {
    background-color: var(--clr-background);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
  }
  
  .client-testimonials .testimonials {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .client-testimonials .testimonial {
    margin: 20px;
    max-width: 300px;
    text-align: left;
  }
  
  .client-testimonials .testimonial img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .client-testimonials .testimonial p {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 600px) {
    .why-work-with-us .content{
      flex-direction: column;
    }
  }