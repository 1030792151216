:root {
    /* primary/main color */
    --clr-primary-1: hsl(194, 30%, 64%);
    --clr-primary-2: hsl(0, 0%, 41%);
    --clr-primary-3: hsl(0, 0%, 8%);
    --clr-primary-4: hsl(205, 86%, 81%);
    --clr-primary-5: plum;
    --clr-background: hsl(335, 67%, 96%);
    --clr-white: #fff;
    --clr-black: #222;
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(210, 22%, 49%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(0, 0%, 66%);
  
    /* primary/main fonts */
    --ff-primary: "Dosis", sans-serif;;
    --ff-secondary: "DM Sans", sans-serif;
    --ff-primary-size: 1.2rem;
    --ff-secondary-size: 1.5rem;

  
    --line-height: 1.5;
    --transition: all 0.3s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1000px;
    --fixed-width: 620px;
    --border-radius: 15px;
    --border-radius-hover:25px;
    --margin: 2rem;
    --padding: 2rem;
  }

body {
    color: var(--clr-grey-1);
    margin: 0 auto;
    padding: 0;
  }
  
  h1, h3, h4, h5, h6 {
    color: var(--clr-primary-3);
  }

h1 {
    font-family: var(--ff-secondary);
    color: var(--clr-primary-3);
    font-size: 3rem;
    font-weight: 1000;
}

h2 {
    font-family: var(--ff-secondary);
    color: var(--clr-primary-3);
    font-size: 2rem;
    font-weight: 1000;
}
  
  a {
    color: var(--clr-primary-1);
    text-decoration: none;
    font-family: var(--ff-primary);

  }
  
  button {
    background-color: var(--clr-primary-5);
    border-radius: var(--border-radius-hover);
    box-shadow: 4px 4px #222;
    padding: .5rem 1rem;
    height: 2rem;
    cursor: pointer;
    margin: var(--margin);
    pointer-events: painted;
}

button:hover {
    background-color: var(--clr-primary-5);
    border-radius: var(--border-radius);
    cursor: pointer;
}

button:active {
    background-color: var(--clr-primary-5);
    border-radius: var(--border-radius);
    border: solid 1px #767676;
    box-shadow: 1px 1px #767676;
    transform: translate(10px, 5px); 
    cursor: pointer;
    padding: .5rem 1rem;
}

    
.button-two {
    background-color: var(--clr-white);
    border-radius: var(--border-radius-hover);
    box-shadow: 4px 4px #222;
    padding: .5rem 1rem;
    height: 2rem;
    cursor: pointer;
    margin: var(--margin);
    color: var(--clr-primary-3);
    }
    
.button-two:hover {
    background-color: var(--clr-white);
    border-radius: var(--border-radius);
    cursor: pointer;
    }
    
.button-two:active {
    background-color: var(--clr-white);
    border-radius: var(--border-radius);
    padding: .5rem 1rem;
    height: 2rem;
    border: solid 1px #767676;
    box-shadow: 1px 1px #767676;
    transform: translate(10px, 5px); 
    cursor: pointer;
    }
      
  