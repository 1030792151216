    .moving-text-container {
        overflow: hidden;
        background-color: var(--clr-background);
        /* padding: 10px 0; */
      }
      
      .parallax {
        overflow: hidden;
      }
      
      .scroller {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        font-size: 7rem;
        font-weight: 900;
        color: var(--clr-primary-3);
      }
      
      .scroller span {
        margin-right: 0rem;
      }
      