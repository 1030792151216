.contact-form {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}
.contact-form .dog{
    position: absolute;
    top: 10%;
    left: 80%;
    width: 200px;
}

.contact-form h2 {
    font-size: 2em;
    color: var(--clr-black);
}

.contact-form p {
    font-size: 1.2em;
    color: #333;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form label {
    width: 80%;
    margin: 10px 0;
    text-align: left;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

