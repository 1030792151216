.graphic-design {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);
}
.content{
    max-width: 1200px;
    margin: 0 auto;
}

.graphic-design-header {
    background-color: var(--clr-background);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
}
.graphic-design-header h1, .graphic-design-header p {
    color: var(--clr-black);
}

.design-collateral {
    display: flex;
    flex-direction: row;
    background-color: var(--clr-white);
    color:var(--clr-black);
    display: flex;
    padding: 50px;
}

.design-collateral .content {
    display: flex;
    flex-direction: row;
    background-color: var(--clr-white);
    color:var(--clr-black);
    display: flex;
    padding: 50px;
}

.design-collateral img {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto; 
}

.design-collateral .content .content-text {
    width: 50%;
    padding: 20px;
    color: var(--clr-black);
}
.design-collateral .content .content-text h2, .design-collateral .content .content-text p {
    color: var(--clr-black);
}
.brand-collateral {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}

.brand-collateral .collateral-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.brand-collateral .item {
    margin: 20px;
    max-width: 200px;
}

.brand-collateral .item img {
    width: 100%;
    border-radius: 10px;
}

.brand-collateral .item p {
    margin-top: 10px;
}

.recent-graphic {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;

}
.graphic-design .recent-graphic h2{
    color:var(--clr-white);
}

.graphics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-around;
    flex-wrap: wrap;
}

.graphic {
    align-self: center;
    justify-self: center;
    max-width: 200px;
    transition: transform 0.5s ease;
}

.graphic:hover {
    transform: scale(1.7);
}

.graphic img {
    width: 70%;
    height: auto;
    border-radius: 10px;
}



.recent-projects {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}
.recent-projects h2{
    color:var(--clr-white);
}

.projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
}

.project {
    align-self: center;
    justify-self: center;
    margin: 10px;
    max-width: 150px;
    transition: transform 0.5s ease;
}
.project:hover {
    transform: scale(1.7);
}

.project img {
    width: 70%;
    border-radius: 10px;
}


@media screen and (max-width: 600px) {
    .design-collateral {
        margin: 0;
        padding: 20px;
    }
    .design-collateral .content{
      flex-direction: column;
      margin: 0;
      padding: 0;
    }
    .design-collateral .content .content-text {
        width: 100%;
    }
  }