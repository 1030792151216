.footer {
    background-color: #ffffff;
    color: #1c1c1c;
    padding: 20px; 
    display: flex; 
    flex-direction: row;
    font-family:  var( --ff-primary);
    justify-content:space-around; 
}

.footer .logo{
    font-size: 1.5em;
    text-decoration: none;
    font-weight:400;
    color:var(--clr-primary-3);
}
.footer p a {
    color:var(--clr-primary-3);
}
.footer h3 {
    color: var(--clr-grey-1);
}

.footer .social-media a {
    margin: 0 10px;
    color: #1c1c1c;;
    font-size: 1.5em;
}

.footer .nav-links {
    display: flex;
    align-items: center;
}

.footer .nav-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #1c1c1c;
    font-weight: lighter;
}

.footer-copyright {
    align-items: center;
    justify-content: center;
    text-align: center;
}

