.scroll-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    background-color: var(--clr-primary-5);
    border-radius: var(--border-radius-hover);
    box-shadow: 4px 4px #222;
    padding: .5rem 1rem;
    height: 2rem;
    cursor: pointer;
    margin: var(--margin);
    pointer-events: painted;
    
}

.scroll-to-top:hover {
    background-color: #555;
}
