.pricing-page {
    text-align: center;
    padding: 20px;
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
}
.comparison-wrapper{
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}


.pricing-page h1 {
    color: #333;
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
}

.plans {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.plan {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
    max-width: 200px;
    flex: 1;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.plan.selected {
    background-color: #ffc107;
}

.pricing-page h1 {
    font-family: var(--ff-secondary);
    color: var(--clr-primary-3);
    font-size: 2rem;
    font-weight: 1000;
}

.top-details {
    height: 80px;
}
.top-details h2 {
    height: 50px;
}

.plan h2 {
    font-size: 1rem;
    margin-bottom: 10px;
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 900;
}

.plan .price {
    color: #333;
    margin-bottom: 10px;
}

.plan p {
    color: #999;
    margin-bottom: 10px;
    font-size: var(--ff-primary-size);
    font-family: var(--ff-primary);
    font-weight: 200;
}


.plan .details p {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 700;
}

.plan button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.plan button:hover {
    background-color: #555;
}

.plan ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 10px;
}

.plan ul li {
    margin-bottom: 10px;
}

.toggle-charges {
    cursor: pointer;
    color: #ffc107;
    font-weight: bold;
}

.additional-charges {
    display: none;
}

.plan.selected .additional-charges {
    display: block;
}
