
/* COOKIES */
.cookies-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cookies-content {
    background-color: #fff;
    color: #000;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .cookies-content h2 {
    margin-top: 0;
  }
  
  .cookies-options {
    margin: 20px 0;
  }
  
  .cookies-options div {
    margin-bottom: 10px;
  }
  
  .show-options-button {
    padding: 10px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .cookies-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .cookies-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
  }
  
  .cookies-buttons button:first-child {
    background-color: #4caf50;
    color: white;

  }
  
  .cookies-buttons button:last-child {
    background-color:#555; 
    color: white;
  }

  button{
    margin: 0;
  }
  